import React, { FC, useEffect } from 'react'
import { useToggle } from 'react-use'
import { ErrorModal } from '../ErrorModal'
import { useTabApiConfig } from '../../services/TabApiProvider/ProviderConfigs'
import { useTabApiAppointment } from '../../services/TabApiProvider/ProviderAppointment'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { intro } from '@root/utils/routes'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

export const GlobalErrorModal: FC = () => {
  const [isErrorModalVisible, toggleErrorModalVisibility] = useToggle(false)
  const history = useHistory()

  const { error: errorTabAppointment } = useTabApiAppointment()
  const { error: errorTabConfig } = useTabApiConfig()
  const { error: errorTabForm } = useTabApiForm()

  const error = errorTabAppointment || errorTabConfig || errorTabForm

  useEffect(() => {
    if (error) {
      toggleErrorModalVisibility(true)
      console.error(error)
    } else {
      toggleErrorModalVisibility(false)
    }
  }, [error, toggleErrorModalVisibility])

  const handleClose = () => {
    toggleErrorModalVisibility(false)
    history.push(intro())
  }
  const { t } = useTranslation()

  return (
    <ErrorModal
      isOpen={isErrorModalVisible}
      onRequestClose={handleClose}
      error={error}
      errorMessage={
        error?.message.includes('Forbidden: ') &&
        error?.message.includes('but it is not current')
          ? t('errorModal.formIsNotLatest')
          : undefined
      }
    />
  )
}
