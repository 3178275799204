import { Typography } from '@components/Typography'
import { DocumentRecap } from '@root/api/models/DocumentRecap'
import { useConfig } from '@root/Context'
import { isCaOrUs } from '@root/utils/utils'
import isBase64 from 'is-base64'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'
import { useToggle, useWindowSize } from 'react-use'
import styled, { css } from 'styled-components'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { InputGroup } from '../InputGroup'
import { TextField } from '../TextField'
import { Toggle } from '../Toggle'
import { checkSignatureFormat, extractInvalidChars } from './signatureFormat'

const TypographyWrapper = styled.div`
  align-items: center;

  & p {
    font-weight: bold;
    font-size: 14px;

    &:nth-of-type(2) {
      margin-left: 35px;
    }
  }
`

export interface SignaturePayload {
  signature: string
  signatureType: 'DRAW' | 'TEXT'
  hasAccepted: boolean
  event: React.MouseEvent
  isContinue: boolean
  docType: string
}
export interface SignatureProps {
  onSubmit: (data: SignaturePayload) => void
  currentDocumentId: string
  currentDocumentType: string
  isDocumentsSigned: boolean
  recapDocumentsSigned: DocumentRecap[]
  isEECA?: boolean
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  padding: 20px 0;
  margin: 0 !important;
  text-align: left;
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      padding: 40px;
    }
  `}

  & > * + * {
    margin-bottom: 33px;
  }
`

const SignatureCanvasWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    width: 90%;
    height: 1px;
    background-color: #b5b5b5;
    position: absolute;
    left: 5%;
    top: 75%;
  }
`

const SignaturePadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      align-items: flex-end;
      flex-direction: row;
    }
  `}

  & canvas {
    border-radius: 10px;
    border: 2px solid #666;
    color: #000;
  }
`

const ClearDrawnSignature = styled.button`
  background: none;
  color: ${({ theme: { colors } }) => colors.primary};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline;
  text-transform: capitalize;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      margin-left: 21px;
    }
  `}

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme: { colors } }) => colors.disabled};
  }
`

const ConfirmAndSubmitButton = styled(Button)`
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      width: auto;
    }
  `}
`

const MAX_INVALID_CHARACTERS = 25

export const Signature: React.FC<SignatureProps> = ({
  onSubmit,
  currentDocumentId,
  currentDocumentType,
  isDocumentsSigned,
  recapDocumentsSigned,
  isEECA = false,
}) => {
  const { t } = useTranslation()
  const { country } = useConfig()
  const currentDocument = recapDocumentsSigned.find(
    (d) => d.documentId === currentDocumentId
  )
  const isAllSigned = isCaOrUs(country) && isDocumentsSigned
  const isSigned = isCaOrUs(country)
    ? !!(isAllSigned || currentDocument?.hasAccepted)
    : false

  const [hasAccepted, toggleHasAccepted] = useToggle(isSigned)
  const [signature, setSignature] = useState<string>(
    isSigned ? (currentDocument ? currentDocument.signature || '' : '') : ''
  )
  const [isSignatureValid, setIsSignatureValid] = useState<boolean>(
    currentDocument?.signatureType === 'TEXT'
      ? checkSignatureFormat(currentDocument?.signature)
      : true
  )
  const [drawIt, toggleSignatureKind] = useToggle(
    isBase64(signature, {
      allowEmpty: false,
      mimeRequired: true,
    })
  )
  const sigRef = useRef<SignatureCanvas>(null)
  const { width } = useWindowSize()

  const handleSubmit = (event: React.MouseEvent) => {
    if (!checkSignatureFormat(signature)) {
      setIsSignatureValid(false)
      return
    }
    onSubmit({
      signature,
      hasAccepted,
      signatureType: drawIt ? 'DRAW' : 'TEXT',
      event,
      isContinue: isDocumentsSigned,
      docType: currentDocumentType,
    })
  }

  const handleDrawEnd = () => {
    if (sigRef.current) {
      const signature = sigRef.current.toDataURL()

      setSignature(signature)
    }
  }

  const handleDrawClear = () => {
    if (sigRef.current) {
      sigRef.current.clear()

      setSignature('')
    }
  }

  useEffect(() => {
    if (isSigned) {
      if (signature) {
        if (
          isBase64(signature, {
            mimeRequired: true,
            allowEmpty: false,
          })
        ) {
          if (sigRef.current) {
            sigRef.current.fromDataURL(signature)
            sigRef.current.off()
          }
        }
      }
    }
  }, [isSigned, signature])

  useEffect(() => {
    if (!drawIt) setIsSignatureValid(checkSignatureFormat(signature))
  }, [drawIt, signature])

  const invalidChars = extractInvalidChars(signature)

  return (
    <StyledWrapper>
      {!isSigned && (
        <InputGroup>
          <InputGroup.Label>
            {t('legalDocument.legalGuardianSignature')}
          </InputGroup.Label>

          <Toggle
            offLabel={t('core.typeIt')}
            onLabel={t('core.drawIt')}
            name="signatureKind"
            value="signature"
            checked={drawIt}
            onChange={() => {
              setSignature('')
              setIsSignatureValid(true)
              toggleSignatureKind()
            }}
          />
        </InputGroup>
      )}

      {!drawIt && (
        <>
          <TextField
            label={t('core.fullName')}
            name="signature"
            value={
              isSigned
                ? (currentDocument ? currentDocument.signature || '' : '') || ''
                : signature
            }
            onChange={({ currentTarget: { value } }) => setSignature(value)}
            required
            disabled={isSigned}
          />
          {!isSignatureValid && (
            <Typography $variant="p" $align="left">
              {t('legalDocument.signatureInvalid', {
                signature,
                invalidChars: invalidChars
                  .join(', ')
                  .substring(0, MAX_INVALID_CHARACTERS),
              })}
            </Typography>
          )}
        </>
      )}

      {drawIt && (
        <SignaturePadWrapper>
          <SignatureCanvasWrapper>
            <SignatureCanvas
              penColor={'#333'}
              canvasProps={{
                width: Math.min(width - 30, 335),
                height: 87,
              }}
              ref={sigRef}
              clearOnResize={false}
              backgroundColor="white"
              onEnd={handleDrawEnd}
            />
          </SignatureCanvasWrapper>

          {!isSigned && (
            <ClearDrawnSignature
              onClick={handleDrawClear}
              disabled={signature.length === 0}
            >
              {t('clear')}
            </ClearDrawnSignature>
          )}
        </SignaturePadWrapper>
      )}

      <>
        {isEECA && (
          <TypographyWrapper>
            <Typography
              $variant="p"
              $align="left"
              style={{
                textDecoration: 'underline',
              }}
            >
              {t(
                `legalDocument.healthInformationAuthorizationDisclosureTitleEECA`
              )}
              :
            </Typography>
            <Typography $variant="span" $align="left">
              {t(
                `legalDocument.healthInformationAuthorizationDisclosureMessageEECA`
              )}
            </Typography>
          </TypographyWrapper>
        )}
        <Checkbox
          checked={hasAccepted}
          label={
            isEECA
              ? t(`legalDocument.healthInformationAuthorizationEECA`)
              : t(`legalDocument.healthInformationAuthorizationAnz`)
          }
          onChange={toggleHasAccepted}
          disabled={isSigned}
        />
        <ConfirmAndSubmitButton
          disabled={
            (hasAccepted ? false : !(hasAccepted && signature.length !== 0)) ||
            !isSignatureValid
          }
          onClick={handleSubmit}
          data-element-id="X_OEE_Intro_Legal-Submit"
        >
          {isSigned ? t('core.continue') : t('core.confirmAndSubmit')}
        </ConfirmAndSubmitButton>
      </>
    </StyledWrapper>
  )
}
